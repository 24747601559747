@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;800;900&display=swap');

html{
  background: black;
  height: 100%;
}

body {
  height:100%;
}

.vh-100 {
  height: 100vh;  
  height: -webkit-fill-available;
}

.menu-enter {
  opacity: 0;
}

.menu-enter-active {
  opacity: 1;
  transition: opacity ease-in-out 400ms;
}

.menu-exit {
  opacity: 1;
}

.menu-exit-active {
  opacity: 0;
  transition: opacity ease-in-out 400ms;
}

.un { 
  display: inline-block; 
}

nav {
  transition: all 0.20s ease;
}

.nav-scrolled {
  backdrop-filter: saturate(180%) blur(20px);
  background: rgba(30,30,30,0.8);
}

.un:after {
  display:block;
  content: '';
  border-bottom: solid 8px;
  transform-origin: 0% 50%;
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity)); 
  animation: animateUnderline 0.25s ease-in;
}


.swiper-wrapper {
  justify-items: center;
}

.swiper-slide {
  height: 100vh !important;
}

.swiper-button-next {
  color:rgba(245, 158, 11, 1) !important;
}

.swiper-button-prev {
  color: rgba(245, 158, 11, 1) !important;
}

.swiper-button-next:focus {
  outline: 0 !important;
}

.swiper-button-prev:focus {
  outline: 0 !important;
}

.argus-gradient-text {
  background: linear-gradient(45deg, #8BDAD1, #62B8DC);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (min-width: 768px) { 
  .un:after {
    border-bottom: solid 10px;
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)); 
  }
}

@media (min-width: 1280px) { 
  .un:after {
    border-bottom: solid 12px;
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)); 
  }
}

@keyframes animateUnderline {
  0% {
      transform: scaleX(0); 
  }

  100% {
    transform: scaleX(1);  
  }
}